import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  OnInit,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import * as fromDesignSystem from '@serious-system';

import { AuthActions } from './store/auth.actions';
import { authFeature, AuthState } from './store/auth.reducer';

@Component({
  imports: [
    TranslateModule,
    RouterModule,
    fromDesignSystem.ButtonDirective,
    fromDesignSystem.UseSocialIconDirective,
    fromDesignSystem.UseLogoDirective,
  ],
  template: `
    <img sdUseLogo="logo-minimal-filled" class="size-14" />
    <header class="flex flex-col gap-4 items-center text-center">
      <div class="*:typo-h4 tablet-portrait:*:typo-h3 *:font-semibold">
        <h1>{{ 'LOGIN.TITLE_LINE_1' | translate }}</h1>
        <h1>
          {{ 'LOGIN.TITLE_LINE_2' | translate }}
        </h1>
      </div>
      <p class="typo-p1">{{ 'LOGIN.SUBTITLE' | translate }}</p>
    </header>
    <button
      sdButton
      variant="tonal"
      color="primary"
      class="w-full"
      (click)="handleClickLoginCTAContinueWithMicrosoft()"
    >
      <svg sdUseSocialIcon="microsoft-no-bg"></svg>
      <span class="truncate">{{
        'LOGIN.CTA_CONTINUE_WITH_MICROSOFT' | translate
      }}</span>
    </button>
  `,
  styles: `
    :host {
      @apply h-screen w-screen max-w-[480px] m-auto flex-col flex justify-center items-center p-10 gap-10;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPage implements OnInit {
  private readonly authStore = inject<Store<AuthState>>(Store);
  private readonly user = this.authStore.selectSignal(authFeature.selectUser);
  private readonly router = inject(Router);

  constructor() {
    effect(() => {
      if (this.user()) {
        void this.router.navigate(['/chats']);
      }
    });
  }

  ngOnInit(): void {
    this.authStore.dispatch(AuthActions.authenticateUser());
  }

  handleClickLoginCTAContinueWithMicrosoft() {
    this.authStore.dispatch(AuthActions.login());
  }
}
